// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./EFcgyytNZ-0.js";

const cycleOrder = ["cVh34UIXx"];

const variantClassNames = {cVh34UIXx: "framer-v-1k1r52j"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, title, width, ...props}) => { return {...props, DiP3QBE7a: title ?? props.DiP3QBE7a ?? "Pozrite si moje práce"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, DiP3QBE7a, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "cVh34UIXx", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-PXFEX", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1k1r52j", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"cVh34UIXx"} ref={ref} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItQm9sZA==", "--framer-font-family": "\"Inter-Bold\", \"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-text-alignment": "center"}}>Pozrite si moje práce</motion.p></React.Fragment>} className={"framer-ek4wy8"} fonts={["Inter-Bold"]} layoutDependency={layoutDependency} layoutId={"rkdux_CBb"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={DiP3QBE7a} verticalAlignment={"center"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-PXFEX [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PXFEX .framer-1iiabht { display: block; }", ".framer-PXFEX .framer-1k1r52j { height: 50px; overflow: hidden; position: relative; width: 198px; will-change: var(--framer-will-change-override, transform); }", ".framer-PXFEX .framer-ek4wy8 { flex: none; height: 50px; left: calc(50.00000000000002% - 198px / 2); position: absolute; top: calc(50.00000000000002% - 50px / 2); white-space: pre-wrap; width: 198px; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 198
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"DiP3QBE7a":"title"}
 * @framerImmutableVariables false
 */
const FramerEFcgyytNZ: React.ComponentType<Props> = withCSS(Component, css, "framer-PXFEX") as typeof Component;
export default FramerEFcgyytNZ;

FramerEFcgyytNZ.displayName = "pozrite si moje prace c";

FramerEFcgyytNZ.defaultProps = {height: 50, width: 198};

addPropertyControls(FramerEFcgyytNZ, {DiP3QBE7a: {defaultValue: "Pozrite si moje práce", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerEFcgyytNZ, [])